<template>
  <div>
    <ManageForm5 />
  </div>
</template>
<script>
import ManageForm5 from "@/components/ManageForm5/ManageForm5";
export default {
  components: {
    ManageForm5,
  },
  created() { this.year = JSON.parse(
        Decode.decode(localStorage.getItem("yearSelected"))
      );
  },
};
</script>